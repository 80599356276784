import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Pagination } from 'app/core/api/pagination.type';
import { Checklist, ChecklistItem, ChecklistItemResponse, ChecklistsResponse, Classification, ClassificationList } from './checklist.model';
import { LocalStorageService } from '../localstorage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ChecklistService {

    private _classifications: BehaviorSubject<Classification[] | null> = new BehaviorSubject(null);
    errorMessage: string = "Something went wrong ";
    pagination: Pagination;
    private _checklists: BehaviorSubject<Checklist[] | null> = new BehaviorSubject(null);
    private _checklistDetail: BehaviorSubject<Checklist | null> = new BehaviorSubject(null);
    checklistPagination: Pagination;
    private _checklistItems: BehaviorSubject<ChecklistItem[] | null> = new BehaviorSubject(null);
    checklistItemPagination: Pagination;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private _localStorage: LocalStorageService) {
    }

    get classifications$(): Observable<Classification[]> {
        return this._classifications.asObservable();
    }
    get checklists$(): Observable<Checklist[]> {
        return this._checklists.asObservable();
    }
    get checklistDetail$(): Observable<Checklist> {
        return this._checklistDetail.asObservable();
    }
    get checklistItems$(): Observable<ChecklistItem[]> {
        return this._checklistItems.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Classification apis
     */
    getClassificationsInitial(): Observable<ClassificationList> {
        return this.getClassifications(null, null, null, null, null)
    }
    getClassifications(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<ClassificationList> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<ClassificationList>(`${BASE_URL}checklist/classification/`, {
            params: {
                page: page ? ++page : 1,
                // ordering: sort ? sort : '',
                search: query ? query : '',
                page_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                if (response.data.length == 0)
                    this.errorMessage = "There are no classifications to display!"
                this._classifications.next(response.data);
            })
        );
    }

    searchClassifications(query: string): Observable<ClassificationList> {
        let params = {
            page: 1,
            search: query ? query : '',
            page_size: 100
        }
        return this._httpClient.get<ClassificationList>(`${BASE_URL}checklist/classification/`, {
            params: params
        })
    }

    deleteClassification(classificationId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}checklist/classification/${classificationId}/`).pipe(
            tap((res: any) => {
                this.pagination.total_count -= 1
                this._classifications.next(this._classifications.value.filter(event => event.id !== classificationId));
            }, err => of([]))
        )
    }

    createClassification(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}checklist/classification/`, credentials);
    }

    updateClassification(credentials, classificationId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}checklist/classification/${classificationId}/`, credentials)
    }


    /**
    * Checklist apis
    */
    getChecklistsInitial(): Observable<ChecklistsResponse> {
        return this.getChecklists(null, null, null, null, null)
    }
    getChecklists(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, classification: number = null): Observable<ChecklistsResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            // ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10
        }
        if (classification != null && classification > 0)
            params['classification'] = classification

        return this._httpClient.get<ChecklistsResponse>(`${BASE_URL}checklist/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.checklistPagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                if (response.data.length == 0)
                    this.errorMessage = "There are no checklists to display!"
                this._checklists.next(response.data);
            })
        );
    }

    getChecklistDetails(checklistId): Observable<Checklist> {
        return this._httpClient.get<Checklist>(`${BASE_URL}checklist/${checklistId}/`).pipe(
            tap((res: any) => {
                this._checklistDetail.next(res);
            }, err => of([]))
        )
    }

    deleteChecklist(checklistId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}checklist/${checklistId}/`).pipe(
            tap((res: any) => {
                this.checklistPagination.total_count -= 1
                this._checklists.next(this._checklists.value.filter(event => event.id !== checklistId));
            }, err => of([]))
        )
    }

    createChecklist(credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}checklist/`, credentials);
    }

    updateChecklist(credentials, checklistId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}checklist/${checklistId}/`, credentials)
    }

    /**
    * Checklist apis
    */
    getChecklistItemsInitial(checklist: number): Observable<ChecklistItemResponse> {
        return this.getChecklistItems(null, null, null, null, null, checklist)
    }
    getChecklistItems(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, checklist: number): Observable<ChecklistItemResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            // ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10
        }

        return this._httpClient.get<ChecklistItemResponse>(`${BASE_URL}checklist/${checklist}/summary/`, {
            params: params
        }).pipe(
            tap((response) => {
                // this.checklistItemPagination = {
                //     page: --response.page,
                //     total_count: response.total_count
                // };
                if (response.data.length == 0)
                    this.errorMessage = "There are no items to display!"
                this._checklistItems.next(response.data);
            })
        );
    }

    deleteChecklistItem(checklist: number, itemId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}checklist/${checklist}/items/${itemId}/`).pipe(
            tap((res: any) => {
                // this.checklistPagination.total_count -= 1
                this._checklistItems.next(this._checklistItems.value.filter(event => event.item_id !== itemId));
            }, err => of([]))
        )
    }

    createChecklistItem(credentials, checklist: number): Observable<any> {
        return this._httpClient.post(`${BASE_URL}checklist/${checklist}/items/`, credentials);
    }

    updateChecklistItem(credentials, checklistId: number, itemId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}checklist/${checklistId}/items/${itemId}/`, credentials)
    }

    /**
* Checklist apis
*/
    getChecklistSubmissionsInitial(checklist: number): Observable<ChecklistsResponse> {
        return this.getChecklistSubmissions(null, null, null, null, null, checklist)
    }
    getChecklistSubmissions(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, checklist: number): Observable<ChecklistsResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            // ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10
        }

        return this._httpClient.get<ChecklistsResponse>(`${BASE_URL}checklist/${checklist}/submissions/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.checklistPagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                if (response.data.length == 0)
                    this.errorMessage = "There are no submissions to display!"
                this._checklists.next(response.data);
            })
        );
    }

    getSubmissionDetails(checklistId, submissionId): Observable<Checklist> {
        return this._httpClient.get<Checklist>(`${BASE_URL}checklist/${checklistId}/submissions/${submissionId}/`).pipe(
            tap((res: any) => {
                this._checklistDetail.next(res);
            }, err => of([]))
        )
    }
}
